/*
 * Comcast CONFIDENTIAL
 *
 * Copyright 2003 - 2016 Comcast Corporation
 * All Rights Reserved.
 *
 * NOTICE:  All information contained herein is, and remains
 * the property of Comcast Corporation and its suppliers,
 * if any.  The intellectual and technical concepts contained
 * herein are proprietary to Comcast Corporation
 * and its suppliers and may be covered by U.S. and Foreign Patents,
 * patents in process, and are protected by trade secret or copyright law.
 * Dissemination of this information or reproduction of this material
 * is unlawful and strictly forbidden unless prior written permission is obtained
 * from Comcast Corporation.
 */

// UPGRADED COMPONENTS
import { StatusConstant } from '../../constants/status.constant';
import { DashboardConstant } from '../../constants/dashboard.constant';

import { DynamicSpots } from '../spots/spots-dynamicitems';
import { DynamicOrders } from '../common/DynamicItems/orders-dynamicitems';

import * as Chart from 'chart.js';
import * as moment from 'moment-timezone';
import * as _ from 'underscore';

//// CONTROLLER ////

export class DashboardController {
    // Bindable properties
    public welcomeMessage: any;
    public accountId: any;
    public userId: any;
    public isFranchise: any;
    public accountType: any;
    public user: any = '';
    public dashboards: any = [];
    public deliveryParams: any;
    public spotParams: any;
    public orderParams: any;
    public spotConstants: any;
    public orderConstants: any;
    public dashboardConstants: any;
    public systemMessage: any;
    public constants: any = {};
    public selectedTimeSpan: any = 1;
    public spots: any = [];
    public dynamicSpotItems: any = null;
    public dynamicOrderItems: any = null;
    public date: any = null;
    public spotStatusChart: any = null;
    public orderStatusChart: any = null;
    public sentStatuses: string[] = ['SENT', 'SENDING'];
    public uploadingStatuses: string[] = ['PROCESSING', 'UPLOADED', 'UPLOADING', 'VERIFYING'];
    public showSpotChart: any = true;
    public showOrderChart: any = true;
    public orderDashboardLabelOrder: string[] = [
        'PENDING_MEDIA',
        'PENDING_SPOT_METADATA',
        'FAILED',
        'PENDING_TRAFFIC',
        'DRAFT',
        'SUBMITTED',
        'ACTIVE',
        'COMPLETED',
        'CANCELLED',
        'WAITING',
    ];
    public spotDashboardLabelOrder: string[] = [
        'PENDING_MEDIA',
        'PENDING_META',
        'REJECTED',
        'PENDING_PROD_SVCS',
        'PENDING_QC',
        'UPLOADING_PROCESSING',
        'UNSENT',
        'SENDING_SENT',
        'CANCELLED',
    ];

    static get $inject() {
        return [
            '$timeout',
            'AssetResourceFactory',
            'SpotResourceFactory',
            '$sce',
            '$stateParams',
            '$scope',
            '$window',
            'loginService',
            'ReceiverResourceFactory',
            'ProviderResourceFactory',
            'DashboardResourceFactory',
            'WelcomeMessageResourceFactory',
            'OrderResourceFactory',
            '$state',
            'StatusService',
            '$q',
        ];
    }

    constructor(
        public $timeout: any,
        public AssetResourceFactory: any,
        public SpotResourceFactory: any,
        public $sce: any,
        public $stateParams: any,
        public $scope: any,
        public $window: any,
        public loginService: any,
        public ReceiverResourceFactory: any,
        public ProviderResourceFactory: any,
        public DashboardResourceFactory: any,
        public WelcomeMessageResourceFactory: any,
        public OrderResourceFactory: any,
        public $state: any,
        public StatusService: any,
        public $q: any
    ) {
        /* BINDABLE: INJECTS */
        Object.assign(this, {
            $timeout,
            AssetResourceFactory,
            SpotResourceFactory,
            $sce,
            $stateParams,
            $scope,
            $window,
            loginService,
            ReceiverResourceFactory,
            ProviderResourceFactory,
            DashboardResourceFactory,
            WelcomeMessageResourceFactory,
            OrderResourceFactory,
            $state,
            StatusService,
            $q,
        });

        let statusConstants = new StatusConstant();
        let dashboardConstants = new DashboardConstant();

        let vm = this;

        //This section extends the horizontalBar graph in order to draw on the new labels and counts without triggering an animation on hover
        Chart.defaults.barModified = Chart.defaults.horizontalBar;

        var customBar = Chart.controllers.horizontalBar.extend({
            draw(ease:any) {
                Chart.controllers.horizontalBar.prototype.draw.call(this, ease);
                let meta = this.getMeta();
                /*
                    This function sets up the text style, then iterates through each model (the labels, essentially).
                    It also sets up the border for the letters so that we can see them on the white background.
                    Then, it iterates through the labels, appends the corresponding Status Count from the data object, and draws it.
                */
                var ctx = this.chart.ctx;
                ctx.font = '900 18px Helvetica';
                ctx.textAlign = 'left';
                ctx.textBaseline = 'center';

                meta.data.forEach(function (dataset:any) {
                    var model = dataset._model;
                    var left = dataset._xScale.left;
                    var count = meta.controller._data[dataset._index];
                    ctx.strokeStyle = model.backgroundColor;
                    ctx.lineWidth = 0;
                    ctx.fillStyle = count === 0 ? model.backgroundColor : 'white'; // label color
                    var status =
                        model.label.status === 'Unsent'
                            ? 'Unsent/Reslate Needed'
                            : model.label.status;
                    var label = status + ' (' + count + ')';
                    ctx.fillText(label, left + 15, model.y + 4);
                    ctx.strokeText(label, left + 15, model.y + 4);
                });
            },
        });

        Chart.controllers.barModified = customBar;

        /* BINDABLE: DATA */
        // Session Objects
        vm.welcomeMessage = loginService.getSessionData().account.dashboardWelcome;
        vm.accountId = loginService.getSessionData().accountId;
        vm.userId = loginService.getSessionData().id;
        vm.isFranchise = loginService.getSessionData().franchiseCustomer;
        vm.accountType = loginService.getSessionData().accountType;
        // Constants
        vm.deliveryParams = dashboardConstants.delivery;
        vm.spotParams = dashboardConstants.spot;
        vm.orderParams = dashboardConstants.order;
        vm.spotConstants = statusConstants.spots;
        vm.orderConstants = statusConstants.orders;
        vm.dashboardConstants = dashboardConstants;

        /* EVENTS */
        //Register any event listeners
    }

    navigate(dashboardItem:any) {
        let vm = this;
        let routeType = dashboardItem.itemType.toUpperCase();
        let route;
        vm.constants = vm.dashboardConstants[dashboardItem.itemType.toLowerCase()];

        switch (routeType) {
            case 'DELIVERY':
                route = !vm.isFranchise ? 'deliveryList' : 'promoList';
                break;
            case 'SPOT':
                route = 'spotList';
                break;
            case 'ORDER':
                route = 'orderList';
                break;
            default:
                route = 'dashboard';
        }

        vm.$state.go(route, vm._parseParams(dashboardItem.DashboardParameters));
    }

    openSpotDetail(id:number) {
        let vm = this;
        vm.$state.go('spotDetail', { id });
    }

    openOrderDetail(id:number) {
        let vm = this;
        vm.$state.go('orderDetail', { id });
    }

    getItemsInTimespan(timespan:any) {
        let vm = this;

        vm.date =
            'gte:' +
            moment()
                .subtract(timespan, 'days')
                .hour(0)
                .minute(0)
                .second(0)
                .format('MM-DD-YYYY h:mm:ss a');

        vm.dynamicSpotItems = new DynamicSpots(
            { dateUpdated: vm.date },
            vm.SpotResourceFactory,
            vm.AssetResourceFactory,
            vm.StatusService,
            vm.loginService,
            vm.$timeout,
            vm.$scope,
            vm.accountType
        );

        vm.dynamicOrderItems = new DynamicOrders(
            { dateUpdated: vm.date },
            vm.OrderResourceFactory,
            vm.StatusService,
            vm.loginService,
            vm.$timeout,
        );

        vm._sortSpotsForChart();
        vm._sortOrdersForChart();
    }

    _sortOrdersForChart() {
        let vm = this;

        vm.OrderResourceFactory.getOrderStatuses(
            { dateUpdated: vm.date, accountId: vm.accountId },
            {},
            function success(orders:any) {
                let rows = orders.data.rows;
                if (rows.length === 0) {
                    vm.showOrderChart = false;
                    return;
                }
                vm.showOrderChart = true;
                vm.orderDashboardLabelOrder.forEach((element) => {
                    if (rows.filter((item:any) => item.status === element).length === 0) {
                        rows.push({ status: element, statusCount: 0 });
                    }
                });

                let sortedRows = _.sortBy(rows, function (obj:any) {
                    return _.indexOf(vm.orderDashboardLabelOrder, obj.status);
                });

                vm._updateOrderChart(sortedRows);
            },
            function failure(err:any) {
                console.log(err);
            }
        );
    }

    _sortSpotsForChart() {
        let vm = this;

        vm.SpotResourceFactory.getSpotStatuses({ dateUpdated: vm.date, accountId: vm.accountId }).subscribe(
            (spots:any) => {
                /*
                    This whole section deals with bundling up similar statuses, such as the sent statuses
                    and uploading/processing statuses
                */
                let sentBundle;
                let uploadBundle;
                let rows = spots.rows;

                //Leave and don't attempt to draw if no spots were returned.
                if (rows.length === 0) {
                    vm.showSpotChart = false;
                    return;
                }
                vm.showSpotChart = true;

                [sentBundle, uploadBundle] = [
                    rows.filter((item:any) => vm.sentStatuses.includes(item.status)),
                    rows.filter((item:any) => vm.uploadingStatuses.includes(item.status)),
                ];

                let sentCount = 0;
                sentBundle.map((item:any) => (sentCount += parseInt(item.statusCount, 10)));

                let uploadCount = 0;
                uploadBundle.map((item:any) => (uploadCount += parseInt(item.statusCount, 10)));

                rows = rows.filter(
                    (item:any) =>
                        !vm.sentStatuses.includes(item.status) &&
                        !vm.uploadingStatuses.includes(item.status)
                );

                //Push these statuses onto the rows if we actually have any of them
                if (sentCount > 0) {
                    rows.push({
                        status: 'SENDING_SENT',
                        statusCount: sentCount,
                    });
                }

                if (uploadCount > 0) {
                    rows.push({
                        status: 'UPLOADING_PROCESSING',
                        statusCount: uploadCount,
                    });
                }

                vm.spotDashboardLabelOrder.forEach((element) => {
                    if (rows.filter((item:any) => item.status === element).length === 0) {
                        rows.push({ status: element, statusCount: 0 });
                    }
                });

                let sortedRows = _.sortBy(rows, function (obj:any) {
                    return _.indexOf(vm.spotDashboardLabelOrder, obj.status);
                });

                vm._updateSpotChart(sortedRows);
            },
            (err:any) => {
                console.log(err);
            }
        );
    }

    _updateOrderChart(chartData:any) {
        let vm = this;
        let labels = chartData.map((item:any) => {
            vm.orderConstants[item.status].searchTerm = item.status;
            return vm.orderConstants[item.status];
        });
        let colors = chartData.map((item:any) => vm.orderConstants[item.status].dashboardColor);
        let counts = chartData.map((item:any) => item.statusCount);

        if (vm.orderStatusChart) {
            vm.orderStatusChart.destroy();
        }

        let ctx = document.getElementById('orderStatusChart');

        vm.orderStatusChart = new Chart(ctx, {
            type: 'barModified',
            data: {
                labels,
                datasets: [
                    {
                        label: '# of Orders',
                        data: counts,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                hover: {
                    onHover(e:any, el:any) {
                        $('#orderStatusChart').css('cursor', el[0] ? 'pointer' : 'default');
                    },
                },
                onClick(evt:any, chartElement:any) {
                    //Doing the sorting for us when we click a bar
                    if (chartElement[0] && chartElement[0]._model) {
                        let searchStatus = chartElement[0]._model.label.searchTerm;

                        vm.dynamicOrderItems = new DynamicOrders(
                            {
                                OrdersStatus: searchStatus,
                                dateUpdated: vm.date,
                            },
                            vm.OrderResourceFactory,
                            vm.StatusService,
                            vm.loginService,
                            vm.$timeout,
                        );

                        vm._sortOrdersForChart();
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                            gridLines: {
                                display: false,
                                color: '#3e4db1',
                            },
                            maxBarThickness: 35,
                            barPercentage: 1.0,
                            categoryPercentage: 0.95,
                        },
                    ],
                    xAxes: [
                        {
                            barThickness: 'flex',
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                },
                animation: {
                    duration: 0,
                },
            },
        });
    }

    _updateSpotChart(chartData:any) {
        let vm = this;
        let labels = chartData.map((item:any) => {
            vm.spotConstants[item.status].searchTerm = item.status;
            return vm.spotConstants[item.status];
        });
        let colors = chartData.map((item:any) => vm.spotConstants[item.status].dashboardColor);
        let counts = chartData.map((item:any) => item.statusCount);

        //Necessary to prevent old iterations of the chart from continuing to exist and occasionally being displayed
        if (vm.spotStatusChart) {
            vm.spotStatusChart.destroy();
        }

        let ctx = document.getElementById('spotStatusChart');

        vm.spotStatusChart = new Chart(ctx, {
            type: 'barModified',
            data: {
                labels,
                datasets: [
                    {
                        label: '# of Spots',
                        data: counts,
                        backgroundColor: colors,
                        borderColor: colors,
                        borderWidth: 1,
                    },
                ],
            },
            options: {
                hover: {
                    onHover(e:any, el:any) {
                        $('#spotStatusChart').css('cursor', el[0] ? 'pointer' : 'default');
                    },
                },
                onClick(evt:any, chartElement:any) {
                    //Doing the sorting for us when we click a bar
                    if (chartElement[0] && chartElement[0]._model) {
                        let searchStatus = chartElement[0]._model.label.searchTerm;

                        //If the user has clicked one of the bundled statuses,
                        //then we apply all those statuses to the search
                        if (searchStatus === 'SENDING_SENT') {
                            searchStatus = vm.sentStatuses;
                        } else if (searchStatus === 'UPLOADING_PROCESSING') {
                            searchStatus = vm.uploadingStatuses;
                        }

                        vm.dynamicSpotItems = new DynamicSpots(
                            { SpotsStatus: searchStatus, dateUpdated: vm.date },
                            vm.SpotResourceFactory,
                            vm.AssetResourceFactory,
                            vm.StatusService,
                            vm.loginService,
                            vm.$timeout,
                            vm.$scope,
                            vm.accountType
                        );

                        vm._sortSpotsForChart();
                    }
                },
                maintainAspectRatio: false,
                legend: {
                    display: false,
                },
                scales: {
                    yAxes: [
                        {
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                            gridLines: {
                                display: false,
                                color: '#3e4db1',
                            },
                            maxBarThickness: 35,
                            barPercentage: 1.0,
                            categoryPercentage: 0.95,
                        },
                    ],
                    xAxes: [
                        {
                            barThickness: 'flex',
                            ticks: {
                                beginAtZero: true,
                                display: false,
                            },
                            gridLines: {
                                display: false,
                                drawBorder: false,
                            },
                        },
                    ],
                },
                tooltips: {
                    enabled: false,
                },
                animation: {
                    duration: 0,
                },
            },
        });
    }

    _parseParams(params:any) {
        let vm = this;
        let SearchParams:any = {};
        let dateStrings = ['received', 'updated', 'created', 'valid'];

        // eslint-disable-next-line
        params.map((item:any) => {
            if (dateStrings.indexOf(item.parameter.toLowerCase()) > -1) {
                let dateParam = dateStrings[dateStrings.indexOf(item.parameter.toLowerCase())];

                SearchParams[vm.constants[`${dateParam}DateFrom`].param] = vm._getDateFrom(
                    item.searchValue
                );
                SearchParams[vm.constants[`${dateParam}DateTo`].param] = vm._getDateTo(
                    item.searchValue
                );
            } else {
                SearchParams[vm.constants[item.parameter].param] = item.searchValue;
            }
        });

        SearchParams.isFranchise = vm.isFranchise;
        return SearchParams;
    }

    _getDateFrom(date:any) {
        return date.split('|')[0].length === 0 ? null : date.split('|')[0];
    }

    _getDateTo(date:any) {
        return date.split('|')[1] ? date.split('|')[1] : null;
    }

    _getReceiver() {
        let vm = this;

        vm.ReceiverResourceFactory.get(
            { id: vm.accountId },
            {},
            function success(receiver:any) {
                vm.user = receiver;
                vm._getAccountDashboards();
            },
            function failure(err:any) {
                console.log(err);
            }
        );
    }

    _getProvider() {
        let vm = this;

        vm.ProviderResourceFactory.get(
            { id: vm.accountId },
            {},
            function success(provider:any) {
                vm.user = provider;
                vm.getItemsInTimespan(vm.selectedTimeSpan);
                vm._getAccountDashboards();
            },
            function failure(err:any) {
                console.log(err);
            }
        );
    }

    _getAccountDashboards() {
        let vm = this;
        vm.DashboardResourceFactory.getAllForAccount({ id: vm.user.id }).subscribe(
            (response:any) => {
                vm.dashboards = response;
                for (let i = 0; i < vm.dashboards.length; i++) {
                    vm.dashboards[i].DashboardViewers = vm._getDashboardViewers(vm.dashboards[i]);
                }
            },
            (err:any) => {
                console.log(err);
            }
        );
    }

    _getAccountWelcomeMessage(accountType:any) {
        let vm = this;

        vm.WelcomeMessageResourceFactory.get({ accountType }, function (response:any) {
            vm.systemMessage = response.message === '' ? false : response.message;
        });
    }

    _getDashboardViewers(dashboard:any) {
        let vm = this;

        vm.DashboardResourceFactory.getDashboardViewers({ dashboardId: dashboard.id }).subscribe(
            (viewers:any) => {
                dashboard.DashboardViewers = viewers;
            },
            (err:any) => {
                console.log(err);
                return [];
            }
        );
    }

    $onInit() {
        let vm = this;

        // Retrive system-wide messages for the account type
        vm._getAccountWelcomeMessage(vm.loginService.getSessionData().accountType);

        switch (vm.loginService.getSessionData().accountType) {
            case 'RECEIVER':
                vm._getReceiver();
                break;
            case 'PROVIDER':
                vm._getProvider();
                break;
        }
    }
}
