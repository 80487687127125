import { DashboardController } from './dashboard-controller';
var dependencies = ['comcast.common.authentication', 'comcast.common.communication', 'comcast.common.navigation', 'comcast.directives', 'ngMaterial', 'comcast-addelivery-constants', 'ui.router'];
var defaultName = angular.module('comcast.dashboard', dependencies).config(['$stateProvider', 'addelivery.state.constants', function ($stateProvider, stateConstants) {
  $stateProvider.state('dashboard', {
    url: '/dashboard',
    views: {
      mainNav: stateConstants.mainNav,
      leftNav: stateConstants.leftNav,
      content: {
        template: require('./dashboard-template.html'),
        controller: DashboardController,
        controllerAs: 'vm'
      }
    },
    access: {
      requiresLogin: true,
      requiredPermissions: ['User'],
      permissionCheckType: 'AtLeastOne'
    }
  });
}]).name;
export { defaultName as default, dependencies };